<template>
  <Layout class="pb-5">
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-md-12 content_margin_bottom">
        <div class="card">
          <div class="card-body">
            <div>
              <div class="form-group w-25">
                <!-- <multiselect
                  :options="categories"
                  v-model="category"
                  placeholder="Categories"
                  :custom-label="getCategory"
                  :close-on-select="true"
                  @select="getCategoryId($event)"
                >
                </multiselect> -->
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Card -->

            <div class="table-responsive mb-0 courses__row">
              <div
                class="courses__col"
                v-for="course in courses"
                :key="course.id"
              >
                <b-card
                  :title="course.title"
                  class="m-0"
                  :img-src="url() + course.avatar"
                  @click="getDetails(course.id)"
                  :img-alt="course.title"
                  img-top
                  responsive="sm"
                  id="my-table"
                  :items="courses"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :fit-by.sync="sortBy"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                >
                  <b-card-text>
                    {{ course.overview | truncate(40, "...") }}
                  </b-card-text>
                </b-card>
                <div class="d-flex justify-content-end bg-light p-2">
                  <b-button
                    @click="enroll(course.id)"
                    :class="
                      enrolledIds.includes(course.id)
                        ? `btn-danger`
                        : `btn-success`
                    "
                    :disabled="enrolledIds.includes(course.id)"
                    >{{
                      enrolledIds.includes(course.id) ? "Enrolled" : "Enroll"
                    }}</b-button
                  >
                  <router-link
                    :to="`/user/${userId}/course/${course.id}/details`"
                    class="btn btn-primary ml-3"
                    >Details</router-link
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      aria-controls="my-table"
                    ></b-pagination>
                    <!-- <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      aria-controls="itemList"
                      align="center"
                    ></b-pagination> -->
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
/* eslint-disable */
import Layout from "@/views/layouts/main.vue";
import store from "@/state/store";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import multiselect from "vue-multiselect";
import baseUrl from './../../../helpers/url.js'
export default {
  page: {
    title: "Courses",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: {
    PageHeader,
    Layout,
    multiselect,
  },

  data() {
    return {
      // data card meta
      userId: this.$route.params.id,
      requestError: null,

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      sortDesc: false,
      sortBy: "slug",
      filter: null,
      filterOn: [],
      pageOptions: [10, 25, 50, 100],

      title: "Enroll",
      items: [
        {
          text: "Courses",
        },
        {
          text: "Enroll",
          active: true,
        },
      ],

      courses: [],
      enrolledCourses: [],
      category: "",
      categories: [],
    };
  },
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  methods: {
    url() {
      return baseUrl()
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    getCategory({ name }) {
      return `${name}`;
    },

    // getCategoryId(event) {
    //   let category_slug = event.slug;
    //   this.$router.push(`/user/${this.userId}/courses/${category_slug}`);
    // },

    getDetails(courseId) {
      this.$router.push(`/user/${this.userId}/course/${courseId}/details`);
    },

    enroll(courseId) {
      const data = {
        courseId: courseId,
        individualId: this.userId,
      };
      this.$store
        .dispatch("enroll/enrollCourse", data)
        .then((response) => {
          this.fetchData();
          toasterMixin({ message: response.data.msg, type: "success" });
        })
        .catch((err) => {
          this.requestError = "An error occured";
        });
    },

    fetchData() {
      this.$store
        .dispatch("enroll/fetchAllCourses")
        .then((response) => {
          this.courses = [...response.data.course];
          this.totalRows = this.courses.length;
        })
        .catch((err) => {
          if (err?.response?.data === undefined)
            this.requestError = "An error occured";
        });

      this.$store
        .dispatch("enroll/myCourses", this.userId)
        .then((response) => {
          this.enrolledCourses = [...response.data.Enrollments];
        })
        .catch((err) => {
          if (err?.response?.data === undefined)
            this.requestError = "An error occured";
        });
    },
  },

  computed: {
    itemsForList() {
      return this.courses.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
    rows() {
      return this.courses && this.courses.length;
    },
    enrolledIds() {
      if (!this.enrolledCourses || this.enrolledCourses.length == 0) return [];

      const ids = this.enrolledCourses.map((course) => course.course_id);
      return ids;
    },
  },

  created() {
    this.fetchData();
    store
      .dispatch("course/fetchCategories")
      .then((response) => {
        this.categories = response.data;
        
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>

<style>
.card img {
  width: 100%;
  height: 340px;
  display: block;
  cursor: pointer;
  margin: 0 auto 30px;
  background-size: cover;
  background-position: center center;
}

.courses__row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 1rem;
  padding: 10px;
}

.courses__col {
  height: 400px;
  height: auto;
  width: 100%;
  box-shadow: 2px 2px 2px 1px rgba(138, 137, 137, 0.2);
}

.courses__colText {
  padding: 10px;
}

.courses__colTitle {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
